import React, { Component } from "react";
import { CarouselData } from "./CarouselData";
import { Link } from "react-router-dom";
import "./Carousel.css";

export default class Carousel extends Component {
  componentDidMount() {
    window.$(".carousel").carousel({
      pause: false,
    });
  }
  render() {
    let classActiveIndicators = "";
    let classActiveSlides = "";
    return (
      <div id="homeCarousel" className="carousel slide carousel-fade" data-ride="carousel" data-interval="10000">
        <ol className="carousel-indicators">
          {CarouselData.map((elem, index) => {
            if (index === 0) {
              classActiveIndicators = "active";
            } else {
              classActiveIndicators = "";
            }
            return <li key={index} data-target="#homeCarousel" data-slide-to={index} className={classActiveIndicators} />;
          })}
          ;
        </ol>
        <div className="carousel-inner">
          {CarouselData.map((elem, index) => {
            if (index === 0) {
              classActiveSlides = "active";
            } else {
              classActiveSlides = "";
            }
            return (
              <div key={index} className={`carousel-item ${classActiveSlides}`}>
                <div className="overlay-image" style={{ backgroundImage: `url(${elem.imgSrc})` }}></div>
                <div className="carousel-text-container container d-flex h-100 flex-column justify-content-center">
                  <h1 className="carousel-title animate__animated animate__fadeInLeft">{elem.title}</h1>
                  <p className="carousel-text animate__animated animate__fadeInDown animate__delay-1s">{elem.text}</p>
                  <Link className="link-to-page" key={index} to={elem.href}>
                    <p className="carousel-button btn btn-lg btn-primary animate__animated animate__fadeInDown animate__delay-2s">{elem.btnText}</p>
                  </Link>
                </div>
              </div>
            );
          })}
          ;
        </div>
        <a className="carousel-control-prev" href="#homeCarousel" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true" />
        </a>
        <a className="carousel-control-next" href="#homeCarousel" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true" />
        </a>
      </div>
    );
  }
}
