import React, { Component } from "react";

export default class Historia extends Component {
  render() {
    return (
      <div className="container">
        <p className="nosotros-section-paragraph">
          Desde el año 1997, somos una empresa que acompaña el desarrollo industrial de nuestra
          región aunando experiencia profesionalidad y ética empresarial, con accionistas de 35 años
          de experiencia en el rubro y continuidad familiar, en la ejecución de obras de movimientos
          de suelos, ingeniería vial, civil y arquitectura. En el año 2012, con la construcción de
          un predio en el parque industrial de Zárate, dimos un gran paso en nuestra consolidación
          como empresa referente del mercado, incorporando talleres para el mantenimiento y
          reparación de maquinarias y vehículos, depósitos de logística y áreas de servicios para
          todo el personal.
        </p>
        <p className="nosotros-section-paragraph">
          En la actualidad, contamos con un gran capital humano, conformado por profesionales
          experimentados, administrativos y trabajadores calificados, que junto a la Certificación
          Técnica y Financiera para Obras Nacionales y Provinciales y la Certificación ISO 9001, nos
          permiten estar a la altura de las obras y los clientes más exigentes.
        </p>
      </div>
    );
  }
}
