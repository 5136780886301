import React, { Component } from "react";
import "./Jumbotron.css";

export default class Jumbotron extends Component {
  render() {
    return (
      <section
        data-aos="zoom-in-up"
        className="jumbotron"
        style={{ backgroundColor: "orangeRed" }}
      >
        <h1 className="jt-title">{new Date().getFullYear() - 1997} AÑOS</h1>
        <hr className="jt-hr" />
        <p className="jt-text">
          De trayectoria, responsabilidad, cumplimiento de las normas ISO,
          compromiso con el medioambiente. <br /> <br /> Nuestros valores
          acompañan el desarrollo industrial y económico de la zona.
        </p>
      </section>
    );
  }
}
