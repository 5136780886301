import React, { Component } from "react";
import "./Alquileres.css";
import Header from "../Header/Header";
import Results from "./Results/Results";
import Filter from "./Filter/Filter";
export default class Alquileres extends Component {
  componentDidMount() {
    window.$(function () {
      window.AOS.init({
        offset: 100,
        duration: 700,
        easing: "ease-out-quad",
        once: 0,
      });
      window.addEventListener("load", window.AOS.refresh);
    });
  }

  constructor(props) {
    super(props);
    this.state = { filter: "none" };
  }

  changeFilter = async (event) => {
    await this.setState({
      filter: event.target.value,
    });
  };

  render() {
    return (
      <main className="main">
        <Header
          title={"Alquiler de equipos"}
          text={
            "CARJOR permite el alquiler de equipos mensual o semanal para adaptarse a las necesidades " +
            " de su proyecto."
          }
          href={"#alquiler"}
          imgSrc={"/images/equipos.jpg"}
          btnText={"Ver catálogo"}
        />
        <div id="alquiler"></div>
        <Filter handler={this.changeFilter} />
        <Results filter={this.state.filter} />
      </main>
    );
  }
}
