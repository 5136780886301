import React, { Component } from "react";
import Header from "../Header/Header";
import "./Contacto.css";
import Map from "./Map/Map";
import TextTitleSeparator from "../Home/TextTitleSeparator/TextTitleSeparator";
import EmailForm from "./EmailForm/EmailForm";

export default class Contacto extends Component {
  componentDidMount() {
    window.$(function () {
      window.AOS.init({
        offset: 100,
        duration: 300,
        easing: "ease-out",
        once: 0,
      });
      window.addEventListener("load", window.AOS.refresh);
    });
  }

  render() {
    return (
      <div className="main">
        <Header
          title={"Contacto"}
          text={"Contáctese con nosotros para obtener precios, información, presupuestos y demás."}
          href={"#contacto"}
          imgSrc={"/images/Deposito.JPG"}
          btnText={"Contáctenos"}
        />
        <main id="contacto">
          <TextTitleSeparator title="Visítenos" />
          <Map
            title="Oficinas céntricas"
            direction="3 de Febrero 59, Zárate"
            telephone="(03487) 434-739"
            schedule="Lun. a Vie. 08:00hs - 18:00hs"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3304.017991912085!2d-59.026573984249175!3d-34.09467953824989!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bb0b68a259297f%3A0x961e471d8723f428!2sCarjor%20SRL%20-%20Empresa%20Constructora!5e0!3m2!1ses-419!2sar!4v1611370736911!5m2!1ses-419!2sar"
            reversed="not-reversed"
          />
          <Map
            title="Oficinas parque industrial"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3303.972835544715!2d-59.08114018424929!3d-34.0958363383103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bb0a8f536d801b%3A0xac18e448a0e26433!2sCARJOR%20SRL!5e0!3m2!1ses-419!2sar!4v1611370715215!5m2!1ses-419!2sar"
            direction="Camino de la costa brava, acceso parque industrial Km. 3.5, Zárate, Buenos Aires"
            telephone="(03487) 450-540"
            schedule="Lun. a Vie. 08:00hs - 18:00hs"
            reversed="reversed"
          />
          <TextTitleSeparator title="Contáctenos" />
          <EmailForm />
        </main>
      </div>
    );
  }
}
