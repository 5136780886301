import React, { Component } from "react";
import "./Image.css";

export default class Image extends Component {
  render() {
    if (this.props.srcImg.includes(".mp4")) {
      return (
        <div className="img-container">
          <video autoPlay loop muted className="obra-principal-video image" loading="lazy" alt={this.props.title}>
            <source src={`${this.props.srcImg}`} />
          </video>
          <div className="overlay">
            <div className="text">
              <h3>{this.props.title}</h3>
              <p>{this.props.text}</p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="img-container">
          <img className="image" src={`${this.props.srcImg}`} loading="lazy" alt={this.props.title} />
          <div className="overlay">
            <div className="text">
              <h3>{this.props.title}</h3>
              <p>{this.props.text}</p>
            </div>
          </div>
        </div>
      );
    }
  }
}
