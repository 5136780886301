import React from "react";
import "./Map.css";

export default function Map(props) {
  return (
    <div className={`map-container ${props.reversed}`}>
      <div data-aos="zoom-in" data-aos-delay="500" className="map-description">
        <div className="description">
          <h2>{props.title}</h2>
          <hr className="description-hr" />
          <p className="icon-paragraph">
            <img
              className="map-description-icon"
              src="https://icongr.am/feather/map-pin.svg?size=128&color=ff4500"
              alt="map pin icon"
            />
            {props.direction}
          </p>
          <p className="icon-paragraph">
            <img
              className="map-description-icon"
              src="https://icongr.am/feather/clock.svg?size=128&color=ff4500"
              alt="schedule icon"
            />
            {props.schedule}
          </p>
          <p className="icon-paragraph">
            <img
              className="map-description-icon"
              src="https://icongr.am/clarity/phone-handset.svg?size=128&color=ff4500"
              alt="phone icon"
            />
            {props.telephone}
          </p>
        </div>
      </div>
      <div data-aos="zoom-in" className="map-div">
        <iframe
          className="map"
          title={props.title}
          src={props.src}
          tabIndex="0"
        ></iframe>
      </div>
    </div>
  );
}
