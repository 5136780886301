export const CarouselData = [
  {
    imgSrc: "/images/foto_item_2.jpg",
    title: "Obras Viales",
    text:
      "El constante desarrollo del país y de la región nos garantizan trabajos de relevancia para el mantenimiento y construcción de rutas, caminos rurales y todo tipo de movimiento de suelo. Para todos nuestros trabajos ya sean estatales o privados se utilizan maquinarias de última generación junto con operarios altamente calificados.",
    href: "/obras",
    btnText: `Ver obras`,
  },
  {
    imgSrc: "/images/foto_item_1.jpg",
    title: "Obras civiles",
    text:
      "Un importante número de empresas nacionales e internacionales confían en nosotros para realizar trabajos que pueden incluir desde el proyecto hasta la ejecución integral de la obra. Nuestros ingenieros y arquitectos, con una amplia trayectoria en el medio, garantizan los plazos de entrega y la calidad de la obra.",
    href: "/obras",
    btnText: `Ver obras`,
  },
  {
    imgSrc: "/images/foto_item_4.jpg",
    title: "Alquiler de equipos",
    text:
      "El alquiler de equipos, con choferes especializados, permite realizar trabajos rápidos y de excelente calidad de terminación.",
    href: "/alquileres",
    btnText: `Ver catálogo`,
  },
  {
    imgSrc: "/images/foto_item_3.jpg",
    title: "Asesoramiento técnico",
    text:
      "Nuestra oficina técnica está conformada por ingenieros y arquitectos expertos en el estudio y análisis técnico del proyecto: viabilidad, planificación, organización. Proveen un análisis del mejor sistema, de los plazos de entrega y organización de la obra, lo que nos permite realizar presupuestos acordes a sus requerimientos.",
    href: "/contacto",
    btnText: `Contáctenos`,
  },
];
