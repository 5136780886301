import React, { Component } from "react";
import Header from "../Header/Header";
import ObrasData from "./Obras.json";
import "./Obras.css";

export default class Obras extends Component {
  constructor(props) {
    super(props);
    this.state = { obras: ObrasData };
  }

  componentDidMount() {
    window.$(function () {
      window.AOS.init({
        offset: 100,
        duration: 700,
        easing: "ease-out-quad",
        once: 0,
      });
      window.addEventListener("load", window.AOS.refresh);
    });
  }

  render() {
    return (
      <main className="obras-main">
        <Header
          title={"Obras"}
          text={
            "Con más de 700 obras realizadas, CARJOR dispone de un amplio historial de proyectos," +
            " conózcalos."
          }
          href={"#obras"}
          imgSrc={"/images/OBRAS.jpeg"}
          btnText={"Ver las obras"}
        />
        <section id="obras">
          {this.state.obras.map((el, index) => {
            let year = Object.keys(el)[0];
            return (
              <details key={`details_${index}`} className="obras-detail">
                <summary className="obras-summary">
                  <span className="summary-title">
                    {year} <span className="summary-subtitle">({el[year].length} obras)</span>
                  </span>
                </summary>
                {el[year].map((innerEl, innerIndex) => {
                  return (
                    <div key={`obra_${innerIndex}`} className="obra-container">
                      <div className="obra">
                        <p className="obra-date">{innerEl.FECHA}</p>
                        <p className="obra-title">
                          <b>{innerEl.EMPRESA}</b>
                        </p>
                        <p className="obra-detail">{innerEl.DETALLE}</p>
                      </div>
                      <hr className="obra-hr" />
                    </div>
                  );
                })}
              </details>
            );
          })}
        </section>
      </main>
    );
  }
}
