import React, { Component } from "react";
import "./Results.css";
import alquileresData from "../Alquileres.json";

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      jsonData: [],
      filteredData: [],
      filter: this.props.filter,
    };
  }

  async componentDidMount() {
    this.setState({
      jsonData: alquileresData,
      filteredData: alquileresData,
      loading: false,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.filter !== prevProps.filter) {
      this.setState({
        filteredData: updateResults(this.state.jsonData, this.props.filter),
      });
    }
  }

  render() {
    return this.state.loading ? (
      <div className="animation-container">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    ) : (
      <div data-aos="fade-up" key="catalog_div" id="catalog">
        {this.state.filteredData.map((el, index) => [
          <div key={index} className="card catalog-card p-0">
            <img
              className="card-img-top img-responsive"
              src={el.Foto}
              alt={el.Modelo}
            />
            <div className="card-body">
              <h5 className="card-title">{el.Modelo}</h5>
              <h6 className="card-subtitle">{el.Marca}</h6>
            </div>
            <div className="card-footer">
              <p className="card-text">{el.Tipo}</p>
            </div>
          </div>,
        ])}
      </div>
    );
  }
}

function updateResults(data, filter) {
  if (filter === "none") {
    return data;
  }
  return data.filter((x) => x.Tipo.toLowerCase() === filter);
}

export default Results;
