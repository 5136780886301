import "./App.css";
import Home from "./Components/Home/Home";
import Nosotros from "./Components/Nosotros/Nosotros";
import Alquiler from "./Components/Alquileres/Alquileres";
import Obras from "./Components/Obras/Obras";
import Contacto from "./Components/Contacto/Contacto";
import Footer from "./Components/Footer/Footer";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavBarResponsive from "./Components/NavBar/NavBarResponsive";

function App() {
  return (
    <Router>
      <NavBarResponsive />
      <div className="App">
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/nosotros" component={Nosotros} />
          <Route path="/alquileres" component={Alquiler} />
          <Route path="/obras" component={Obras} />
          <Route path="/contacto" component={Contacto} />
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
