export const MenuItems = [
  {
    title: "Nosotros",
    url: "/nosotros",
  },
  {
    title: "Alquiler de equipos",
    url: "/alquileres",
  },
  {
    title: "Obras",
    url: "/obras",
  },
  {
    title: "Contacto",
    url: "/contacto",
  },
];
