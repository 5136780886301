import React from "react";
import "./EmailForm.css";

export default class EmailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      "form-name": "",
      "form-subject": "",
      "form-email": "",
      "form-message": "",
      "form-corp": "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    let eventName = event.target.name;
    let eventValue = event.target.value;
    this.setState({ [eventName]: eventValue });
  }

  handleSubmit(e) {
    const name = this.state["form-name"];
    const subject = this.state["form-subject"];
    const message = this.state["form-message"];
    let corporation = this.state["form-corp"] === "" ? "No contesta." : this.state["form-corp"];
    const email = this.state["form-email"];

    e.preventDefault();
    window.Email.send({
      SecureToken: "060c9501-fc08-4948-b73f-a8a0cde7de04",
      To: "empresacarjor@gmail.com",
      From: "mailSenderCarjor@gmail.com",
      Subject: subject,
      Body: `
      <div style="margin: 0 auto;width: 66.66%; background-color: #0B0014; color: #F5E9E2; font-size:1.2rem; padding: 10rem; font-family:Roboto, sans-serif;">
        <h2>${subject}</h2>
        <hr style="border:none; height:2px; background-color: orangered; width: 100%">
        <p>
        Ha recibido este email porque una persona se ha contactado a través de la página web de CARJOR:
        </p>
        <p style="margin: 5rem 1.5rem">  
          <i>"${message}"</i>
        </p> 
        <h3><u>DATOS DE CONTACTO</u></h3> 
        <li><p><b>NOMBRE</b>: ${name}</p></li>
        <li><p><b>EMPRESA</b>: ${corporation}</p></li>
        <li><p><b>EMAIL</b>: ${email}</p></li>
        <hr style="border:none; height:2px; background-color: orangered; width: 100%">
        <p style="text-align: center; font-size: 1rem; margin-top: 5rem;">Este es un mensaje enviado automáticamente, por favor, no lo responda. En caso de querer responder al mensaje, comuniquese con la dirección de email brindada más arriba.</p>
      <div>`,
    }).then(() => alert("¡Gracias por comunicarse con nosotros!"));
  }

  handleInput() {}

  render() {
    return (
      <div data-aos="fade-up" className="form-container">
        <form id="email-form" onSubmit={this.handleSubmit}>
          <div className="form-item">
            <label className="form-label" htmlFor="form-name">
              Nombre
            </label>
            <input
              className="form-input"
              type="text"
              id="form-name"
              name="form-name"
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="form-item">
            <label className="form-label" htmlFor="form-corp">
              Empresa (opcional)
            </label>
            <input
              className="form-input"
              type="text"
              id="form-corp"
              name="form-corp"
              onChange={this.handleChange}
            />
          </div>
          <div className="form-item">
            <label className="form-label" htmlFor="form-subject">
              Asunto
            </label>
            <input
              className="form-input"
              type="text"
              id="form-subject"
              name="form-subject"
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-item">
            <label className="form-label" htmlFor="form-email">
              eMail
            </label>
            <input
              className="form-input"
              type="text"
              id="form-email"
              name="form-email"
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-item">
            <label className="form-label" htmlFor="form-message">
              Mensaje
            </label>
            <textarea
              className="form-input"
              id="form-message"
              name="form-message"
              onChange={this.handleChange}
              required
            />
          </div>
          <input type="submit" className="btn btn-primary form-button" value="Contactar" />
        </form>
      </div>
    );
  }
}
