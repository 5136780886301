import React from "react";
import HomeHighligths from "./HomeHighlights/HomeHighlights";
import Jumbotron from "./Jumbotron/Jumbotron";
import Carousel from "./Carousel/Carousel";
import Refs from "./Refs/Refs";
import ImageGallery from "./ImageGallery/ImageGallery";
import TextTitleSeparator from "./TextTitleSeparator/TextTitleSeparator";
import Intro from "./Intro/Intro";
import gsap from "gsap";
//import { TimelineMax } from "gsap";
import { useEffect } from "react";

export default function Home() {
  useEffect(() => {
    window.$(function () {
      window.AOS.init({
        offset: 100,
        duration: 700,
        easing: "ease-out-quad",
        once: 0,
      });
      window.addEventListener("load", window.AOS.refresh);
    });

    window.$(".carousel").carousel({
      interval: 10000,
      cycle: true,
    });

    window.$(".carousel").carousel("pause");
    const tl = gsap.timeline();
    tl.to(".intro", { zIndex: 999, autoAlpha: 1, duration: 0 })
      .to(".slider", { zIndex: 999, autoAlpha: 1, duration: 0 })
      .to(".intro-text-line", { y: "0%", duration: 1, stagger: 0.25 })
      .to(".slider", { y: "-100%", duration: 1.5, delay: 0.5 })
      .to(".intro", { y: "-100%", duration: 1 }, "-=1.1")
      .fromTo("nav", { opacity: 0 }, { opacity: 1, duration: 0.25 })
      .fromTo(".carousel-text-container", { opacity: 0 }, { opacity: 1, duration: 1, delay: 0.25 });
  }, []);

  return (
    <div className="home">
      <Intro />
      <Carousel />
      <main>
        <section>
          <Jumbotron />
        </section>
        <section>
          <TextTitleSeparator title="Nos distingue" />
          <HomeHighligths />
        </section>
        <section>
          <TextTitleSeparator title="Confían en nosotros" />
          <Refs />
        </section>
        <section>
          <TextTitleSeparator title="Algunas de nuestras obras" />
          <ImageGallery />
        </section>
      </main>
    </div>
  );
}
