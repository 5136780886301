import React, { Component } from "react";
import HomeHighlight from "./HomeHighlight";
import "./HomeHighlights.css";

export default class HomeHighlights extends Component {
  render() {
    return (
      <div className="row home-highlights">
        <HomeHighlight
          animationDelay={100}
          title="Obras"
          text="Más de 700 obras avalan nuestra trayectoria como empresa constructora líder en la zona."
          imgSrc="/images/Obras.svg"
        />
        <HomeHighlight
          animationDelay={200}
          title="Equipos"
          text="Contamos con maquinaria de última generación, lo que ayuda a mejorar los tiempos y la calidad de nuestras obras."
          imgSrc="/images/Equipos.svg"
        />
        <HomeHighlight
          animationDelay={300}
          title="Profesionales"
          text="Nuestro equipo de profesionales altamente calificado brinda el mejor servicio a nuestros clientes."
          imgSrc="/images/Profesionales.svg"
        />
      </div>
    );
  }
}
