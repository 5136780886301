import React, { Component } from "react";
import "./Nosotros.css";
import Header from "../Header/Header";
import Historia from "./Historia/Historia";
import Politica from "./Politica/Politica";
import NosotrosHighlights from "./NosotrosHighlights/NosotrosHighlights";
import TextTitleSeparator from "../Home/TextTitleSeparator/TextTitleSeparator";

export default class Nosotros extends Component {
  componentDidMount() {
    window.$(function () {
      window.AOS.init({
        offset: 100,
        duration: 700,
        easing: "ease-out-quad",
        once: 0,
      });
      window.addEventListener("load", window.AOS.refresh);
    });
  }

  render() {
    return (
      <div>
        <Header
          title={"Nosotros"}
          text={
            "Conozca más acerca de los orígenes de CARJOR y la historia de cómo se convitió en una de las empresas líderes de la zona."
          }
          href={"#historia"}
          imgSrc={"/images/nosotros.jpg"}
          btnText={"Conózcanos"}
        />
        <main className="nosotros-main d-flex flex-column">
          <div className="row nosotros-row">
            <section data-aos="zoom-in-up" id="historia">
              <TextTitleSeparator title="Historia" />
              <Historia />
            </section>
            <section className="row nosotros-row nosotros-highlights-row">
              <TextTitleSeparator title="Algunos datos" />
              <NosotrosHighlights />
            </section>
            <section data-aos="zoom-in-up" id="politica">
              <TextTitleSeparator title="Política" />
              <Politica />
            </section>
          </div>
        </main>
      </div>
    );
  }
}
