import React, { Component } from "react";
import "./NosotrosHighlights.css";

export default class NosotrosHighlight extends Component {
  render() {
    return (
      <div
        data-aos="zoom-in"
        data-aos-delay={this.props.animationDelay}
        className="col-12 col-lg-4 nosotros-highlight-column"
      >
        <h1 className="nosotros-highlight-title">{this.props.title}</h1>
        <h4 className="nosotros-highlight-subtitle">{this.props.subtitle}</h4>
      </div>
    );
  }
}
