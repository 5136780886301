import React, { Component } from "react";
import Image from "./Image";
import "./ImageGallery.css";

export default class ImageGallery extends Component {
  render() {
    return (
      <div className="container-fluid p-0 image-gallery-container">
        <div className="row image-gallery-row m-0">
          <div className="column image-gallery-column">
            <Image srcImg="/images/obras_principales_1.jpg" title="Complejo Portuario Euroamerica S.A." text="Acceso portuario" />
            <Image srcImg="/images/obras_principales_papelera.mp4" title="La Papelera del Plata S.A." text="Obra civil Maquina Papelera 4" />
            <Image srcImg="/images/Defiba Ejecución de pavimento de Hormigon_1.mp4" title="Defiba" text="Ejecución de pavimento de Hormigón" />
          </div>
          <div className="column image-gallery-column">
            <Image srcImg="/images/Arauco movimiento de Suelos_1.mp4" title="Arauco" text="Movimiento de suelos" />
            <Image srcImg="/images/obras_principales_quilmes_2.jpg" title="Quilmes S.A.I.C.A. y G" text="Fermentación y medidoras" />
            <Image srcImg="/images/Moroni Movimiento de suelos y pavimento_1.mp4" title="Moroni" text="Movimiento de suelos y pavimento" />
          </div>
          <div className="column image-gallery-column">
            <Image srcImg="/images/Arenera Vendaval Movimiento de suelos_1.mp4" title="Arenera Vendaval" text="Movimiento de suelos" />
            <Image srcImg="/images/Capilla del señor Movimiento de suelos_1.mp4" title="Capilla del Señor" text="Movimiento de suelos" />
            <Image srcImg="/images/Defiba Ejecución de Suelo Cemento_1.mp4" title="Defiba" text="Ejecución de Suelo Cemento" />
          </div>
        </div>
      </div>
    );
  }
}
