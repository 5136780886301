import React, { Component } from "react";
import "./Filter.css";

export default class Filter extends Component {
  render() {
    return (
      <div key="filter_div" className="filter-dropdown-container">
        <select onChange={this.props.handler} id="filter-dropdown">
          <option value="none">Todos los equipos</option>
          <option value="camión">Camiones</option>
          <option value="acoplado">Acoplados</option>
          <option value="tractor">Tractores</option>
          <option value="tanque regador">Tanques regadores</option>
          <option value="rodillo">Rodillos</option>
          <option value="minicargadora">Minicargadoras</option>
          <option value="compactador">Compactadores</option>
          <option value="motoniveladora">Motoniveladoras</option>
          <option value="palacargadora">Palacargadoras</option>
          <option value="excavadora">Excavadoras</option>
          <option value="cortadora de pasto">Cortadoras de pasto</option>
          <option value="compresor">Compresores</option>
        </select>
      </div>
    );
  }
}
