import React, { Component } from "react";

export default class HomeHighlight extends Component {
  render() {
    return (
      <div
        data-aos="fade-up"
        data-aos-delay={this.props.animationDelay}
        className="col-12 col-lg-4 home-highlight-item"
      >
        <div className="mx-auto features-icons-item mb-5 mb-lg-0 mb-lg-3">
          <div className="d-flex features-icons-icon">
            <img
              className="highlight-item-img"
              src={this.props.imgSrc}
              alt={this.props.title}
            />
          </div>
          <h3>{this.props.title}</h3>
          <p className="highlight-text lead mb-0">{this.props.text}</p>
        </div>
      </div>
    );
  }
}
