import React, { Component } from "react";
import { MenuItems } from "./MenuItems";
import { Link } from "react-router-dom";
import "./NavBar.css";

export default class NavBarResponsive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: "not-colored",
    };
    this.changeColor = this.changeColor.bind(this);
  }

  listenScrollEvent = () => {
    let expanded = document
      .querySelector("#navbarNavAltMarkup")
      .classList.contains("show");
    if (window.scrollY === 0 && !expanded) {
      this.setState({ color: "not-colored" });
    } else {
      this.setState({ color: "colored" });
    }
  };

  changeColor() {
    if (window.scrollY === 0) {
      if (this.state.color === "not-colored") {
        this.setState({ color: "colored" });
      } else {
        this.setState({ color: "not-colored" });
      }
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);
  }

  render() {
    return (
      <nav className={`navbar fixed-top navbar-expand-lg ${this.state.color}`}>
        <Link to="/" style={{ textDecoration: "none" }}>
          <h2 className="navbar-brand" href="/">
            CARJOR
          </h2>
        </Link>
        <button
          onClick={this.changeColor}
          className="navbar-toggler custom-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon custom-toggler"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNavAltMarkup"
        >
          <div className="navbar-nav">
            {MenuItems.map((item, index) => {
              return (
                <Link
                  key={index}
                  to={item.url}
                  style={{ textDecoration: "none" }}
                >
                  <li key={index} className="nav-item nav-link">
                    {item.title}
                  </li>
                </Link>
              );
            })}
          </div>
        </div>
      </nav>
    );
  }
}
