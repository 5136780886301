import React, { Component } from "react";
import NosotrosHighlight from "./NosotrosHighlight";

export default class NosotrosHighlights extends Component {
  render() {
    return [
      <NosotrosHighlight
        animationDelay={100}
        key="highlight_1"
        title="+700"
        subtitle="Obras realizadas"
      />,
      <NosotrosHighlight
        animationDelay={200}
        key="highlight_2"
        title="+150"
        subtitle="Equipos disponibles"
      />,
      <NosotrosHighlight
        animationDelay={300}
        key="highlight_3"
        title="+100"
        subtitle="Empleados"
      />,
    ];
  }
}
