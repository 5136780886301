import React, { Component } from "react";
import RefsData from "./Referencias.json";
import "./Refs.css";

export default class Refs extends Component {
  componentDidMount() {
    window.$(".partners-logos").slick({
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1500,
      arrows: false,
      dots: false,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 1250,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  }

  render() {
    return (
      <div className="partners-logos sliders">
        {RefsData.map((el, index) => {
          return (
            <div key={`slide_${index}`} className="slide">
              <div className="slide-img-container">
                <a className="partner-a" href={el.link} target="_blank" rel="noreferrer">
                  <img className="slide-img" src={el.img} alt={el.name} />
                </a>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
