import React, { Component } from "react";
import "./TextTitleSeparator.css";

export default class TextTitleSeparator extends Component {
  render() {
    return (
      <div className="container separator">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="text-separator section-heading">
              {this.props.title}
            </h2>
            <hr className="my-4 hr-separator" />
          </div>
        </div>
      </div>
    );
  }
}
