import React, { Component } from "react";
import "./Intro.css";

export default class Intro extends Component {
  render() {
    return (
      <div>
        <div className="intro">
          <div className="intro-text">
            <h1 className="hide">
              <span className="intro-text-line">Construyendo el futuro</span>
            </h1>
            <h1 className="hide">
              <span className="intro-text-line">para un desarrollo</span>
            </h1>
            <h1 className="hide">
              <span className="intro-text-line">sustentable.</span>
            </h1>
          </div>
        </div>
        <div className="slider"></div>
      </div>
    );
  }
}
