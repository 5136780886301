import React, { Component } from "react";
import "./Footer.css";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <p>
          {" "}
          Copyright © 1997-{new Date().getFullYear()} CARJOR S.R.L. Todos los
          derechos reservados.
        </p>
      </div>
    );
  }
}
