import React, { Component } from "react";

export default class Politica extends Component {
  render() {
    return (
      <div className="container">
        <p className="nosotros-section-paragraph">
          Nuestro compromiso con la calidad nos permitió obtener la
          certificación de la <strong>NORMA ISO 9001</strong> lo que refleja los
          constantes avances para ofrecer la mejor calidad de servicio posible.
        </p>
        <p className="nosotros-section-paragraph">
          En CARJOR, hemos establecido un Sistema de Gestión de la Calidad con
          el fin de lograr la mejora continua de nuestros servicios.
        </p>
        <p className="nosotros-section-paragraph">
          Para cumplir con esta política, en CARJOR asumimos las siguientes
          directrices:
        </p>
        <ol>
          <li>
            Obtener el reconocimiento y satisfacción de nuestros clientes.
          </li>
          <li>
            Aumentar el rendimiento y eficacia general de nuestra organización
            basándonos en una gestión por procesos.
          </li>
          <li>
            Cumplimiento de las necesidades y expectativas de las partes
            interesadas pertinentes.
          </li>
          <li>Implantar un pensamiento basado en la Gestión de Riesgos.</li>
        </ol>
        <p className="nosotros-section-paragraph">
          CARJOR está comprometida con el cumplimento de la legislación
          aplicable a nuestro sector, teniendo en cuenta a la vez, los
          requisitos internos y de nuestros clientes.
        </p>
        <p className="nosotros-section-paragraph">
          Nuestra organización se compromete con la mejora continua de la
          eficacia de nuestro sistema.
        </p>
      </div>
    );
  }
}
