import React, { Component } from "react";
import "./Header.css";

class Header extends Component {
  render() {
    return (
      <div className="header-container">
        <div>
          <div id="header-grid-text">
            <h1 className="header-title">{this.props.title}</h1>
            <p className="header-text">{this.props.text}</p>
            <a href={this.props.href} className="carousel-button btn btn-lg btn-primary header-button">
              {this.props.btnText}
            </a>
          </div>
        </div>
        <div className="image-header-overlay"></div>
        <div className="image-div" style={{ backgroundImage: `url(${this.props.imgSrc})` }}></div>
      </div>
    );
  }
}

export default Header;
